<template>
    <div class="choose-section pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <small>Why Choose Us</small>
                <h2>Why People Choose</h2>
                <h2>Xmatiq Technologies</h2>
                <p>We take pride in our ability to deliver projects on time and within budget, understanding
the importance of uninterrupted business operations. Our agile development approach
allows us to adapt to changing requirements and rapidly respond to market dynamics,
ensuring that our clients stay ahead of the competition.</p>
            </div>
            <div class="process-content">
                <div class="row justify-content-center">
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-four.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Multiple Domains Expertise</h3>
                                <p>Our team of experts possesses extensive
knowledge and experience in Cutting-Edge technologies.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-five.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Tailored Solutions</h3>
                                <p>We understand that every business is unique, and we create
customized solutions that align with your specific requirements and objectives.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="process-item process-item-center border">
                            <div class="process-thumb">
                                <img src="../../assets/images/process/process-six.png" alt="shape">
                            </div>
                            <div class="process-text">
                                <h3>Client-Centric Focus</h3>
                                <p>We prioritize your satisfaction and actively involve you
throughout the development lifecycle, ensuring that your vision is translated into
a successful software solution.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>